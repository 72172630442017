@tailwind base;
@tailwind components;
@tailwind utilities;
/* 
========================
      BUTTON THREE
========================
*/
.btn-three {
  color: #fff;
  transition: all 0.5s;
  position: relative;
}
.btn-three::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.1);
  transition: all 0.3s;
}
.btn-three:hover::before {
  opacity: 0;
  transform: scale(0.5, 0.5);
}
.btn-three::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border: 1px solid rgba(255, 255, 255, 0.5);
  transform: scale(1.2, 1.2);
}
.btn-three:hover::after {
  opacity: 1;
  transform: scale(1, 1);
}

.btn {
  line-height: 50px;
  height: 50px;
  text-align: center;
  cursor: pointer;
}

.gradient,
.active {
  background-color: rgba(255, 255, 255, 0.1);
}

.active {
  color: #fff;
  padding: 1rem;
  border-radius: 100%;
}

/* Arrow */
@keyframes moveUpAndDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

.animated-arrow {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: moveUpAndDown 1.5s infinite;
}

@layer base {
  body {
    @apply font-secondary text-lg leading-8 text-white;
  }
  .h2 {
    @apply font-primary text-[32px] mb-6 tracking-[10%] uppercase;
  }
  .h3 {
    @apply font-primary font-semibold text-[26px] mb-6 leading-[46px];
  }
  .btn {
    @apply rounded-full font-primary text-white font-medium;
  }
  .btn-sm {
    @apply h-[48px] px-6 text-sm;
  }
  .btn-lg {
    @apply h-[56px] px-10 text-base;
  }
  .btn-link {
    @apply text-base;
  }
  .section-banner {
    @apply py-14 lg:h-[85vh] flex justify-center items-center;
  }
  .section {
    @apply py-14 lg:h-full flex justify-center items-center;
  }
  .btn-three {
    @apply lg:text-base text-[14px] lg:px-10 px-4;
  }
}
